export const SKILLS = [
    'React',
    'React Native',
    'NodeJs',
    'Express',
    'Mongo DB',
    'Javascript',
    'Wordpress',
    'Bootstrap',
    'Google Cloud Functions',
    'Google Firebase'
]